header {
  margin-top: 12px;
  display: block;
}
.header {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #000;
  padding: 25px 0;
}
.header .header-item {
  margin: 0 15px;
  text-decoration: none;
  color: #fff;
  font-size: 16px;
  font-family: "Baskervville", serif;
  font-weight: 400;
}
.header .active {
  margin: 0 15px;
  text-decoration: none;
  color: #fff;
  font-size: 16px;
  font-family: "Baskervville", serif;
  font-weight: 400;
  border-bottom: 1px solid #fff;
  padding-bottom: 6px;
}
.bras-btn {
  display: none;
}
.mobile-list .header-item {
  margin: 5px 15px;
  text-decoration: none;
  color: #fff;
  font-size: 24px;
  font-family: "Baskervville", serif;
  font-weight: 400;
}

.mobile-list {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.left-item-hide {
  display: none;
}

@media (max-width: 480px) {
  .bras-btn {
    display: block;
    margin-right: 12px;
  }
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #000;
    padding: 25px 0;
  }
  header {
    margin-top: 0;
    display: none;
  }
  .mobile-header {
    position: fixed;
    top: 100px;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #000;
    z-index: 100;
    transition: ease 0.5s;
  }
  .left-item-hide {
    display: block;
    background-color: transparent;
    height: 25px;
    width: 52px;
  }
}

#checkbox {
  display: none;
}

.toggle {
  position: relative;
  width: 34px;
  height: 34px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
  transition-duration: 0.3s;
}

.bars {
  width: 100%;
  height: 4px;
  background-color: #fff;
  border-radius: 5px;
  transition-duration: 0.3s;
}

#checkbox:checked + .toggle .bars {
  margin-left: 9px;
}

#checkbox:checked + .toggle #bar2 {
  transform: rotate(135deg);
  margin-left: 0;
  transform-origin: center;
  transition-duration: 0.3s;
}

#checkbox:checked + .toggle #bar1 {
  transform: rotate(45deg);
  transition-duration: 0.3s;
  transform-origin: left center;
}

#checkbox:checked + .toggle #bar3 {
  transform: rotate(-45deg);
  transition-duration: 0.3s;
  transform-origin: left center;
}
